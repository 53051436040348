<template>
  <v-app-bar
      app
      color="rgb(0,0,0,0)"
      elevate-on-scroll
  >
    <v-container class="py-0 fill-height align-center">
      <v-toolbar-title class="logo">
        Logixpie
      </v-toolbar-title>

      <v-spacer/>

      <v-btn
          to="/"
          plain
          @click="eventLogger('trigger_home')"
      >
        Home
      </v-btn>
      <v-btn
          to="/projects"
          plain
          @click="eventLogger('trigger_projects')"
      >
        Projects
      </v-btn>
    </v-container>
  </v-app-bar>
</template>

<script>
export default {
  name: "AppBar",
}
</script>