<template>
  <v-footer
      app
      color="rgb(0,0,0,0)"
  >
    <v-row
        justify="center"
        no-gutters
    >
      <v-btn
          class="mx-2"
          plain
          href="https://www.linkedin.com/company/logixpie/"
          target="_blank"
          icon
          @click="eventLogger('trigger_linkedIn')"
      >
        <v-icon>mdi-linkedin</v-icon>
      </v-btn>
      <v-btn
          class="mx-2"
          plain
          href="https://github.com/logixpie"
          target="_blank"
          icon
          @click="eventLogger('trigger_github')"
      >
        <v-icon>mdi-github</v-icon>
      </v-btn>
      <v-btn
          class="mx-2"
          plain
          href="https://www.facebook.com/LogixpieCorporation"
          target="_blank"
          icon
          @click="eventLogger('trigger_facebook')"
      >
        <v-icon>mdi-facebook</v-icon>
      </v-btn>
      <v-btn
          class="mx-2"
          plain
          href="https://twitter.com/Logixpie_Inc"
          target="_blank"
          icon
          @click="eventLogger('trigger_twitter')"
      >
        <v-icon>mdi-twitter</v-icon>
      </v-btn>
      <v-btn
          class="mx-2"
          plain
          href="https://www.instagram.com/logixpie/"
          target="_blank"
          icon
          @click="eventLogger('trigger_instagram')"
      >
        <v-icon>mdi-instagram</v-icon>
      </v-btn>
      <v-col
          class="py-4 text-center"
          cols="12"
      >
        <div class="text-caption text--secondary">Copyright &copy; {{ new Date().getFullYear() }} <strong class="logo">Logixpie</strong>.</div>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  name: "AppFooter"
}
</script>

<style scoped>

</style>