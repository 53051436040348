import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import vuetify from './plugins/vuetify'
import { initializeApp } from 'firebase/app';
import {getAnalytics, logEvent} from "firebase/analytics";

Vue.config.productionTip = false

const firebaseConfig = {
  apiKey: "AIzaSyBFNUSxvtCb5YOSnJd_-Mi_pehMAQa_y7Q",
  authDomain: "logixpie-app.firebaseapp.com",
  databaseURL: "https://logixpie-app-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "logixpie-app",
  storageBucket: "logixpie-app.appspot.com",
  messagingSenderId: "235004023745",
  appId: "1:235004023745:web:856400b2260c2174149540",
  measurementId: "G-95MTYZMJDB"
};

const app = initializeApp(firebaseConfig);
const analytics  = getAnalytics(app);

Vue.mixin({
  methods: {
    eventLogger: function (eventName) {
      logEvent(analytics, eventName)
    }
  }
})

new Vue({
  router,
  vuetify,
  render: h => h(App),
}).$mount('#app')