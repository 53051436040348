<template>
  <v-app>
    <AppBar/>
    <v-main>
      <v-container fill-height >
        <v-fade-transition mode="out-in">
          <router-view />
        </v-fade-transition>
      </v-container>
    </v-main>
    <AppFooter/>
  </v-app>
</template>

<script>
import AppBar from "@/components/AppBar";
import AppFooter from "@/components/AppFooter";

export default {
  name: 'App',
  components: {
    AppFooter,
    AppBar
  },
  data: () => ({
    //
  }),
};
</script>

<style>
html { overflow-y: auto }
.logo {
  font-family: 'Julius Sans One', sans-serif;
  text-transform: uppercase;
}
#app {
  background: linear-gradient(
      -45deg,
      rgba(255, 0, 0, 1) 0%,
      rgba(255, 154, 0, 1) 10%,
      rgba(208, 222, 33, 1) 20%,
      rgba(79, 220, 74, 1) 30%,
      rgba(63, 218, 216, 1) 40%,
      rgba(47, 201, 226, 1) 50%,
      rgba(28, 127, 238, 1) 60%,
      rgba(95, 21, 242, 1) 70%,
      rgba(186, 12, 248, 1) 80%,
      rgba(251, 7, 217, 1) 90%,
      rgba(255, 0, 0, 1) 100%
  );
  background-size: 800% 800%;

  -webkit-animation: gradient 30s ease infinite;
  -moz-animation: gradient 30s ease infinite;
  animation: gradient 30s ease infinite;
}

@-webkit-keyframes gradient {
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}
@-moz-keyframes gradient {
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}
@keyframes gradient {
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}

</style>