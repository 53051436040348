<template>
  <v-sheet
      class="mx-3"
      color="rgb(0,0,0,0)"
  >
    <v-row
        align="center"
        justify="center"
    >
      <v-col
          cols="12"
      >
        <h1 class="text-h2 text-md-h1 font-weight-thin mb-4 logo">
          Logixpie
        </h1>
        <h5 class="text-body-1 text-md-h5 text--secondary font-weight-light">
          Focused On Building Smart, Accessible & Human-Centered Systems <br>
          Our goal Is To Help People By Enhancing The Fruitfulness Of Their Digital Experiences Through Technology
        </h5>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
  export default {
    name: 'HomeView',
  }
</script>
