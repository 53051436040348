<template>
  <v-row>
    <v-col
        v-if="isData"
        class="text-center"
        cols="12"
    >
      <v-progress-circular
          indeterminate
          size="100"
          width="10"
      />
    </v-col>
    <v-col
        v-else
        v-for="(project, index) in projects"
        :key="index"
        cols="12"
        md="6"
    >
      <v-card
          color="rgb(0,0,0,0.1)"
          shaped
          elevation="10"
          @click="eventLogger(project.name)"
          :href="project.link"
          target="_blank"
      >
        <div class="d-flex flex-no-wrap justify-space-between align-center">
          <div>
            <v-card-title
                class="text-h5"
                v-text="project.name"
            ></v-card-title>

            <v-card-text v-text="project.description"></v-card-text>
          </div>

          <v-avatar
              class="ma-3"
              size="100"
              tile
          >
            <v-img :src="project.logo"></v-img>
          </v-avatar>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import {getDatabase, onValue, ref} from "firebase/database";

export default {
  name: "ProjectsView",
  data() {
    return {
      projects: Array,
      isData: true
    }
  },
  methods: {
    getProjects() {
      if (sessionStorage.getItem('logixpie-projects')) {
        this.projects = JSON.parse(sessionStorage.getItem('logixpie-projects'))
        this.isData = false
      } else {
        onValue(ref(getDatabase(), '/data/projects'), (snapshot) => {
          this.isData = false
          sessionStorage.setItem('logixpie-projects', JSON.stringify(snapshot.val()))
          this.projects = snapshot.val()
        }, {
          onlyOnce: true
        });
      }
    }
  },
  created() {
    this.getProjects()
  }
}
</script>